export const phoneDiscoverabilityConsentHelpUrl =
  'https://en.help.roblox.com/hc/articles/7416652004884';

export const phoneDiscoverabilityOptions = {
  Unset: 'Unset',
  NotDiscoverable: 'NotDiscoverable',
  Discoverable: 'Discoverable'
};

export const phoneDiscoverabilityEventTypes = {
  discoverabilityPageLoad: 'discoverabilityPageLoad',
  discoverabilityPageClick: 'discoverabilityPageClick',
  discoverabilityPageRadioButtonClick: 'discoverabilityPageRadioButtonClick'
};

export const phoneVerificationUpsell = 'phoneVerificationUpsell';
